import React from 'react';
import { path, prop } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { withProps } from 'recompose';

import Layout from '/src/components/layout';
import SEO from '/src/components/atoms/Seo';
import { Row, Col } from '/src/components/atoms/GridRow';
import ContentSection from '/src/components/atoms/ContentSection';
import Button from '/src/components/atoms/Button';

const ThankYouPage = ({ message, links }) => (
  <Layout>
    <SEO title="Thank you for your order | Shop" />
    <ContentSection
      splash
      fullPage
      center
      bgSrc="/images/uploads/eoa_countdown-bg.jpg"
    >
      <Row center>
        <Col width={8} className="tc">
          <h1>Thank you!!</h1>
          {message && <p>{message}</p>}
          {links && links.length ? (
            <p className="space-top">
              <Button secondary component="a" href={links[0]} target="_blank">
                Facebook event page
              </Button>
            </p>
          ) : null}
        </Col>
      </Row>
    </ContentSection>
  </Layout>
);

const messageSelector = createSelector(
  prop('location'),
  path(['state', 'message'])
);

const linksSelector = createSelector(
  prop('location'),
  path(['state', 'followupLinks'])
);

const propSelectors = createStructuredSelector({
  message: messageSelector,
  links: linksSelector,
});

export default withProps(propSelectors)(ThankYouPage);
